import React from "react";

import { graphql } from "gatsby";

import Elements from "../../components/shared/Elements";
import Robots from "../../components/shared/Meta/Robots";
import Page from "../../components/shared/Page";
import SEO from "../../components/shared/SEO";

/**
 * SEO should not index this info page.
 */
const HoyerConnectToPacePage = ({
    data: {
        page: { meta, slug, elements },
    },
}) => {
    return (
        <Page>
            <Robots index={false} />
            <SEO {...{ slug, ...meta }} />
            <Elements {...{ elements }} />
        </Page>
    );
};

export const query = graphql`
    query {
        page: pagesYaml(templateKey: { eq: "pages/hoyerConnectToPace" }) {
            id
            slug
            elements {
                ...EntireElement
            }
            meta {
                description
                title
            }
        }
    }
`;

export default HoyerConnectToPacePage;
